<template>
  <div>
    <batteryCharts :comsmid="mid"></batteryCharts>
  </div>
</template>

<script>
import batteryCharts from "../../user/equipment/Equipment.vue";
// import { getEqList } from "@/api/apis";
import * as equipments from "@/api/apis";
export default {
  components: {
    batteryCharts,
  },
  data() {
    return {
      mid: this.$route.query.mid,
      // email: this.$route.query.email,
      taltimer: null,
    };
  },
  created() {
    this.taltimer = setInterval(() => {
    //   this.getdevlist();
      this.getEquipmentDetail();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.taltimer);
    sessionStorage.removeItem("devfilg");
    sessionStorage.removeItem("devlist");
  },
  methods: {
    async getEquipmentDetail() {
        let tals_a = [];
        let arrdives = [];
      let { data } = await equipments.getEqDetail({ mid: this.mid });
      arrdives.push(data)
      let equipmentList = {};
      
      if (arrdives.length > 0) {
        arrdives.forEach((item, index) => {
          tals_a.push({
            tex: item.name,
            imd: item.mid,
            off: false,
            live: false,
            recs: false,
            onfile: false,
          });
          item.adlist = item.steamAddr;
          if (item.steamAddr) {
            let sturl = JSON.parse(item.steamAddr);
            let videodes = [];
            item.videos = [];
            let stulr = sturl.pullStream;
            stulr.forEach((items, indexs) => {
              items.isfor = false;
              if (items.type === 3) {
                videodes.push(items);
                stulr.splice(indexs, 1);
              }
            });
            if (
              item.deviceConfigDto.pullStream &&
              item.deviceConfigDto.pullStream.length > 0
            ) {
              item.deviceConfigDto.pullStream.forEach((itea, inids) => {
                if (itea.enable == 1 && itea.type !== 3) {
                  stulr[inids].isfor = true;
                }
              });
            }
            item.steamAddr = stulr;
            item.videos = videodes;
          }

          if (item.deviceRealtimeInfoDto.pushstreamout == null) {
            item.deviceRealtimeInfoDto.pushstreamout = [];
          }
          if (item.status == 1) {
            tals_a[index].onfile = true;
          } else {
            tals_a[index].off = true;
          }
          if (
            item.deviceRealtimeInfoDto &&
            item.deviceRealtimeInfoDto.workModeStatus
          ) {
            if (
              item.deviceRealtimeInfoDto.workModeStatus.workMode == 0 &&
              item.deviceRealtimeInfoDto.workModeStatus.workStatus == 1
            ) {
              tals_a[index].live = true;
              tals_a[index].onfile = false;
            }
            if (
              item.deviceRealtimeInfoDto.workModeStatus.workMode == 1 &&
              item.deviceRealtimeInfoDto.workModeStatus.workStatus == 1
            ) {
              tals_a[index].recs = true;
              tals_a[index].onfile = false;
            }
          }
          if (item.deviceConfigDto && item.deviceConfigDto.pullStream) {
            item.deviceConfigDto.pullStream.forEach((ites) => {
              if (ites.type === 3) {
                item.videos = [ites];
              }
            });
          }
          // debugger
          if (item.linkInfo !== null && item.linkInfo.length !== 0) {
            item.linkInfo.forEach((obj) => {
              if (obj.recvRate) {
                obj.recvRate = obj.recvRate.split(",");
              }
              if (obj.signal > 0) {
                obj.signal = Math.round(obj.signal / 20);
              }
            });
          }
          if (
            item.deviceRealtimeInfoDto &&
            item.deviceRealtimeInfoDto.recvRate != null
          ) {
            let sk = item.deviceRealtimeInfoDto.recvRate.split(",");
            let skd = sk[sk.length - 1];
            item.deviceRealtimeInfoDto.recvRate = (
              (Number(skd) * 8) /
              1024
            ).toFixed(2);
            let saw = [];
            sk.forEach((itesa) => {
              let skr = ((Number(itesa) * 8) / 1024).toFixed(2);
              saw.push(skr);
            });
            item.Linechart = saw;
          }
        });
        let skrs = JSON.parse(sessionStorage.getItem("devfilg"));
        if (skrs) {
          if (arrdives[skrs]) {
            equipmentList = JSON.stringify(arrdives[skrs]);
          } else {
            equipmentList = JSON.stringify(arrdives[arrdives.length - 1]);
          }
        } else {
          equipmentList = JSON.stringify(arrdives[0]);
        }
        this.resdata = JSON.parse(equipmentList);
        sessionStorage.setItem("devlist", equipmentList);
      } else {
        sessionStorage.setItem("devlist", null);
      }
      this.tals = tals_a;
    },
    //获取设备列表
    async getdevlist() {
      let tals_a = [];
      let params = {
        email: JSON.parse(sessionStorage.getItem("user")).email,//‘270921607@qq.com’‘JSON.parse(sessionStorage.getItem("user")).email’
        type: "0",
      };
      let res = await getEqList(params);
      //console.log(res)
      let equipmentList = {};
      if (res.data.length > 0) {
        res.data.forEach((item, index) => {
          tals_a.push({
            tex: item.name,
            imd: item.mid,
            off: false,
            live: false,
            recs: false,
            onfile: false,
          });
          item.adlist = item.steamAddr;
          if (item.steamAddr) {
            let sturl = JSON.parse(item.steamAddr);
            let videodes = [];
            let stulr = sturl.pullStream;
            stulr.forEach((items, indexs) => {
              items.isfor = false;
              if (items.type === 3) {
                videodes.push(items);
                stulr.splice(indexs, 1);
              }
            });
            if (
              item.deviceConfigDto.pullStream &&
              item.deviceConfigDto.pullStream.length > 0
            ) {
              item.deviceConfigDto.pullStream.forEach((itea, inids) => {
                if (itea.enable == 1 && itea.type !== 3) {
                  stulr[inids].isfor = true;
                }
              });
            }
            item.steamAddr = stulr;
            item.videos = videodes;
          }

          if (item.deviceRealtimeInfoDto.pushstreamout == null) {
            item.deviceRealtimeInfoDto.pushstreamout = [];
          }
          if (item.status == 1) {
            tals_a[index].onfile = true;
          } else {
            tals_a[index].off = true;
          }
          if (
            item.deviceRealtimeInfoDto &&
            item.deviceRealtimeInfoDto.workModeStatus
          ) {
            if (
              item.deviceRealtimeInfoDto.workModeStatus.workMode == 0 &&
              item.deviceRealtimeInfoDto.workModeStatus.workStatus == 1
            ) {
              tals_a[index].live = true;
              tals_a[index].onfile = false;
            }
            if (
              item.deviceRealtimeInfoDto.workModeStatus.workMode == 1 &&
              item.deviceRealtimeInfoDto.workModeStatus.workStatus == 1
            ) {
              tals_a[index].recs = true;
              tals_a[index].onfile = false;
            }
          }

          if (item.deviceConfigDto && item.deviceConfigDto.pullStream) {
            item.deviceConfigDto.pullStream.forEach((ites) => {
              if (ites.type === 3) {
                res.data[index].videos = [ites];
              }
            });
          }
          if (item.linkInfo !== null && item.linkInfo.length !== 0) {
            item.linkInfo.forEach((obj) => {
              if (obj.recvRate) {
                obj.recvRate = obj.recvRate.split(",");
              }
              if (obj.signal > 0) {
                obj.signal = Math.round(obj.signal / 20);
              }
            });
          }
          if (
            item.deviceRealtimeInfoDto &&
            item.deviceRealtimeInfoDto.recvRate != null
          ) {
            let sk = item.deviceRealtimeInfoDto.recvRate.split(",");
            let skd = sk[sk.length - 1];
            item.deviceRealtimeInfoDto.recvRate = (
              (Number(skd) * 8) /
              1024
            ).toFixed(2);
            let saw = [];
            sk.forEach((itesa) => {
              let skr = ((Number(itesa) * 8) / 1024).toFixed(2);
              saw.push(skr);
            });
            item.Linechart = saw;
          }
        });
        let skrs = JSON.parse(sessionStorage.getItem("devfilg"));
        if (skrs) {
          if (res.data[skrs]) {
            equipmentList = JSON.stringify(res.data[skrs]);
          } else {
            equipmentList = JSON.stringify(res.data[res.data.length - 1]);
          }
        } else {
          equipmentList = JSON.stringify(res.data[0]);
        }
        this.resdata = JSON.parse(equipmentList);
        sessionStorage.setItem("devlist", equipmentList);
      } else {
        sessionStorage.setItem("devlist", null);
      }
      this.tals = tals_a;
      //console.log(this.tals)
    },
  },
};
</script>

<style lang="scss" scoped>
</style>